 .page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f5f5f5; 
}

.content-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #F5F5F5;
}

.App-body {
  margin: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.grid-wrapper {
  flex: 1;
  width: 100%;
}

.validation_message {
  color: #E3242B
}
